import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import {
  CONFIRMATION_MODAL_TYPE,
  GOAL_STATUSES,
  TASK_STATUS,
  TASK_TYPE,
  USER_REVIEW_STATUS,
} from '@learned/constants';
import {
  ICreateGoal,
  IGoal,
  IUser,
  IUserReview,
  IUserReviewPopulated,
  IUserReviewQuestionGoalPlanSettings,
  WithComments,
} from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import uniqBy from 'lodash/uniqBy';
import { useDispatch, useSelector } from 'react-redux';

import { BANNER_TYPES } from '~/components/Banner/types';
import { Button, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import { createGoal, updateGoal, updateGoalPublished } from '~/pages/GoalSetup/actions';

import { ImportGoalsModal } from './Components/ImportGoalsModal';
import { CreateGoalModal } from './Components/PlanGoals/CreateGoalModal';
import { EmptyGoalsPlaceholder } from './Components/PlanGoals/EmptyGoalsPlaceholder';
import { GoalDashboardModal } from './Components/PlanGoals/GoalDashboardModal';
import { GoalsGrid } from './Components/PlanGoals/GoalsGrid';
import PDFViewGoalsGrid from './Components/PlanGoals/PDFView';
import { ContentWrapper, QuestionHeader, QuestionWrapper, TextLabel, StyledBanner } from './design';

import { GOALS_STYLES } from '~/constants/goals';
import useBoolState from '~/hooks/useBoolState';
import { getCurrentGoal } from '~/selectors/currentGoal';
import * as goalsService from '~/services/goals';
import { getGoal } from '~/services/goals';
import * as currentGoalActions from '~/store/currentGoal/actions';
import { COLORS } from '~/styles';

import type { IPopulatedReviewTask } from '../../types';

export interface IQuestionPlanGoalForm extends WithComments {
  answer: string | null;
  isNotApplicable: boolean;
}

interface IQuestionViewPlanGoalProps {
  onEdit?: () => void;
  isDashboard?: boolean;
  userFrom?: IPopulatedReviewTask['userFrom'];
  userReview?: IUserReviewPopulated;
  currentUser?: IUser;
  subTypes: IUserReviewQuestionGoalPlanSettings['subTypes'];
  goalsPlanned?: IUserReview['goalsPlanned'];
  setGoalsPlanned?: Dispatch<SetStateAction<IUserReview['goalsPlanned'] | undefined>>;
  isPDFView?: boolean;
  isPreview?: boolean;
  isGiveReview?: boolean;
}

enum WARNING_MODAL_TYPES {
  DELETE = 'delete',
  ARCHIVE = 'archive',
  PUBLISH = 'publish',
}

export const PlanGoalQuestion = ({
  onEdit,
  userFrom,
  isDashboard,
  userReview,
  currentUser,
  subTypes,
  setGoalsPlanned,
  goalsPlanned,
  isPDFView = false,
  isPreview,
  isGiveReview,
}: IQuestionViewPlanGoalProps) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const $refetchGoals = useBoolState(false);
  const $loading = useBoolState(false);
  const $isUpdate = useBoolState(false);
  const $showWarningModal = useBoolState(false);
  const $openCreateGoalModal = useBoolState(false);
  const $openGoalDashboardModal = useBoolState(false);
  const $openDeletedGoalModal = useBoolState(false);
  const $openImportGoalsModal = useBoolState(false);
  const $isOriginalMode = useBoolState(false);
  const selectedGoal = useSelector(getCurrentGoal);
  const [updatedGoal, setUpdatedGoal] = useState<ICreateGoal | null>(null);
  const [goals, setGoals] = useState<IGoal[] | []>([]);
  const [originalGoals, setOriginalGoals] = useState<IGoal[]>([]);
  const [warningType, setWarningType] = useState<WARNING_MODAL_TYPES | null>(null);

  useEffect(() => {
    const savedGoals = goalsPlanned?.filter((item) => subTypes?.includes(item.subType)) || [];
    // @ts-ignore
    setGoals(mapGoals(savedGoals));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalsPlanned]);

  useEffect(() => {
    const fetchOriginalGoals = async () => {
      $loading.on();
      // @ts-ignore
      const { data } = await goalsService.getGoals({ ids: goalsPlanned?.map((item) => item.id) });
      setOriginalGoals(data);
      $loading.off();
    };
    fetchOriginalGoals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$refetchGoals.value, goalsPlanned]);

  const mapGoals = (goals: IGoal[]) => {
    return goals.map((goal) => {
      return {
        ...goal,
        // @ts-ignore
        ...GOALS_STYLES[goal.type],
      };
    });
  };

  const handleRowClick = async (goal: IGoal) => {
    try {
      // @ts-ignore
      const data = await getGoal(goal.id, ['activities', 'goalCycles', 'skills', 'teams'], {
        isGetProgress: true,
      });
      // @ts-ignore
      dispatch(currentGoalActions.setCurrentGoal(data));
      $openGoalDashboardModal.on();
    } catch (error) {
      $openDeletedGoalModal.on();
    }
  };

  const handleDelete = (goal: IGoal) => {
    // @ts-ignore
    dispatch(currentGoalActions.setCurrentGoal(goal));
    setWarningType(WARNING_MODAL_TYPES.DELETE);
    $showWarningModal.on();
  };

  const handleEdit = (goal: IGoal) => {
    // @ts-ignore
    dispatch(currentGoalActions.setCurrentGoal(goal));
    $isUpdate.on();
    $openCreateGoalModal.on();
    $openGoalDashboardModal.off();
  };

  const handleArchive = (goal: IGoal) => {
    // @ts-ignore
    dispatch(currentGoalActions.setCurrentGoal(goal));
    setWarningType(WARNING_MODAL_TYPES.ARCHIVE);
    $showWarningModal.on();
  };

  const removeGoal = async () => {
    if (selectedGoal?.id) {
      onRemoveGoal(selectedGoal);
      await goalsService.deleteGoal(selectedGoal?.id);
      addToast({
        title: i18n._(t`Goal deleted`),
        type: TOAST_TYPES.INFO,
      });
    }
    setWarningType(null);
    $showWarningModal.off();
    $openCreateGoalModal.off();
    $openGoalDashboardModal.off();
    $openImportGoalsModal.off();
    dispatch(currentGoalActions.setCurrentGoal());
  };

  const onChangeArchiveStatus = async () => {
    if (selectedGoal?.status === GOAL_STATUSES.ARCHIVED) {
      await goalsService.updateGoalArchiveStatus(selectedGoal.id, false);
      addToast({
        title: i18n._(t`Goal unarchived`),
        type: TOAST_TYPES.INFO,
      });
    } else {
      await goalsService.updateGoalArchiveStatus(selectedGoal?.id, true);
      addToast({
        title: i18n._(t`Goal archived`),
        type: TOAST_TYPES.INFO,
      });
    }
    setWarningType(null);
    $showWarningModal.off();
    $refetchGoals.toggle();
    $openGoalDashboardModal.off();
  };

  const handleNewGoalModalCloseEvent = () => {
    // @ts-ignore
    dispatch(currentGoalActions.resetCurrentGoal());
    $openCreateGoalModal.off();
    $isUpdate.off();
  };

  const showSuccessToast = (isDraft: boolean) => {
    addToast({
      title: isDraft ? i18n._(t`Goal saved as draft`) : i18n._(t`Goal published`),
      type: TOAST_TYPES.INFO,
    });
  };

  const handleCreateGoal = async (goal: ICreateGoal, isDraft: boolean) => {
    const createdGoal = await createGoal(goal, !isDraft);
    setGoals((prevGoals) => [createdGoal, ...prevGoals]);
    showSuccessToast(isDraft);
    handleNewGoalModalCloseEvent();
  };

  const onUpdatePublishedGoal = async () => {
    if (!updatedGoal) {
      return;
    }
    await updateGoalPublished(updatedGoal);
    setGoals((prevGoals) =>
      prevGoals.map((item) => {
        if (item.id === updatedGoal.id) {
          return updatedGoal as unknown as IGoal;
        } else {
          return item;
        }
      }),
    );
    addToast({
      title: i18n._(t`Goal saved`),
      type: TOAST_TYPES.INFO,
    });
    setUpdatedGoal(null);
    $showWarningModal.off();
    handleNewGoalModalCloseEvent();
    $refetchGoals.toggle();
    $openImportGoalsModal.off();
  };

  const updatePublishedGoal = (goal: ICreateGoal) => {
    setUpdatedGoal(goal);
    $showWarningModal.on();
    setWarningType(WARNING_MODAL_TYPES.PUBLISH);
  };

  const onUpdateGoal = async (goal: ICreateGoal, isDraft: boolean) => {
    await updateGoal(goal, !isDraft);
    showSuccessToast(isDraft);
    setUpdatedGoal(null);
    handleNewGoalModalCloseEvent();
    $refetchGoals.toggle();
  };

  const showEditButton = () => {
    const ineligibleStatusList = [
      USER_REVIEW_STATUS.SIGNING,
      USER_REVIEW_STATUS.PUBLISHED,
      USER_REVIEW_STATUS.ARCHIVED,
    ];

    const userAndCoachTasks =
      userReview?.tasks
        ?.filter((task) =>
          [TASK_TYPE.REVIEW_SELF_EVALUATE, TASK_TYPE.REVIEW_COACH_EVALUATE].includes(task.type),
        )
        .filter((task) => task.status === TASK_STATUS.COMPLETED) || [];

    return (
      !isDashboard ||
      (currentUser &&
        isDashboard &&
        userAndCoachTasks.length > 0 &&
        (userReview?.createdFor === currentUser.id ||
          userReview?.coaches.includes(currentUser.id)) &&
        ineligibleStatusList.findIndex((status) => status === userReview?.status) === -1)
    );
  };

  const onRemoveGoal = (goal: IGoal) => {
    setGoalsPlanned?.(
      (prevGoalsPlanned) => prevGoalsPlanned?.filter(({ id }) => id !== goal.id) || [],
    );
    setGoals((prevGoals) => prevGoals.filter((item) => item.id !== goal.id));
    $isOriginalMode.off();
  };

  const mapGoalsPlanned = (goal: IGoal | IUserReview['goalsPlanned'][number]) => ({
    id: goal.id,
    name: goal.name,
    // @ts-ignore
    subType: goal.subType || goal.type,
  });

  const onImport = (goals: IGoal[]) => {
    setGoals((prevGoals) => uniqBy([...goals, ...prevGoals], 'id'));
  };

  useEffect(() => {
    setGoalsPlanned?.((prevGoals) =>
      uniqBy([...(prevGoals?.map(mapGoalsPlanned) || []), ...goals.map(mapGoalsPlanned)], 'id'),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(goals)]);

  return (
    <QuestionWrapper $isDashboard={isPreview || isDashboard}>
      <ContentWrapper>
        <>
          {isPreview && isGiveReview && (
            <StyledBanner
              type={BANNER_TYPES.INFO}
              subTitle={i18n._(
                t`This question will enable the employee and optionally the coach to create goals.`,
              )}
            />
          )}
          <QuestionHeader>
            <TextLabel>
              <Trans>Goals</Trans>
            </TextLabel>
            {showEditButton() && (
              <>
                {isDashboard ? (
                  <>
                    <Button
                      label={i18n._(t`Edit`)}
                      variant={ButtonVariant.SECONDARY}
                      icon={ICONS.EDIT_PENCIL}
                      onClick={onEdit}
                    />
                  </>
                ) : (
                  <div className="buttons">
                    <Button
                      disabled={isPreview}
                      tooltip={
                        (isDashboard || isPreview) &&
                        i18n._(t`It is not possible to import goals in this preview`)
                      }
                      label={i18n._(t`Import goals`)}
                      variant={ButtonVariant.SECONDARY}
                      icon={ICONS.EXPORT}
                      onClick={$openImportGoalsModal.on}
                    />
                    <Button
                      disabled={isPreview}
                      tooltip={
                        (isDashboard || isPreview) &&
                        i18n._(t`It is not possible to create goals in this preview`)
                      }
                      label={i18n._(t`Create new`)}
                      variant={ButtonVariant.PRIMARY}
                      onClick={$openCreateGoalModal.on}
                    />
                  </div>
                )}
              </>
            )}
          </QuestionHeader>
          <ShowSpinnerIfLoading loading={$loading.value}>
            {goals.length ? (
              isPDFView ? (
                <PDFViewGoalsGrid goals={goals} />
              ) : (
                <>
                  <GoalsGrid
                    goals={goals}
                    originalGoals={originalGoals}
                    isLoading={$loading.value}
                    handleRowClick={handleRowClick}
                    isDashboard={isDashboard}
                    onRemove={onRemoveGoal}
                    onEdit={handleEdit}
                  />
                </>
              )
            ) : (
              <EmptyGoalsPlaceholder />
            )}
          </ShowSpinnerIfLoading>
        </>
      </ContentWrapper>
      {$openCreateGoalModal.value && (
        // @ts-ignore
        <CreateGoalModal
          isUpdate={$isUpdate.value}
          userFrom={userFrom}
          updateGoal={onUpdateGoal}
          handleDelete={handleDelete}
          onClose={handleNewGoalModalCloseEvent}
          refetchGoals={$refetchGoals.toggle}
          handleCreateGoal={handleCreateGoal}
          updatePublishedGoal={updatePublishedGoal}
          subTypes={subTypes}
        />
      )}
      {$openDeletedGoalModal.value && (
        <GoalDashboardModal
          goal={null}
          onClose={() => {
            $openDeletedGoalModal.off();
          }}
        />
      )}
      {$openImportGoalsModal.value && (
        <ImportGoalsModal
          onSave={onImport}
          onClose={$openImportGoalsModal.off}
          subTypes={subTypes}
          goalsToHide={goals.map((item) => item.id)}
          onRowClick={(goal) => {
            $isOriginalMode.on();
            handleRowClick(goal);
          }}
          invokeRefetch={$refetchGoals.value}
          userFrom={userFrom}
        />
      )}
      {$openGoalDashboardModal.value && selectedGoal && (
        <GoalDashboardModal
          goal={selectedGoal}
          onClose={() => {
            $openGoalDashboardModal.off();
            $isOriginalMode.off();
          }}
          handleDelete={handleDelete}
          handleEdit={showEditButton() ? handleEdit : undefined}
          handleArchive={handleArchive}
          isOriginalMode={$isOriginalMode.value}
        />
      )}
      {$showWarningModal.value && (
        <ConfirmationModal
          type={
            warningType === WARNING_MODAL_TYPES.DELETE
              ? CONFIRMATION_MODAL_TYPE.DELETE
              : CONFIRMATION_MODAL_TYPE.WARNING
          }
          description={
            warningType === WARNING_MODAL_TYPES.DELETE
              ? i18n._(t`Are you sure you want to delete this goal?`)
              : warningType === WARNING_MODAL_TYPES.ARCHIVE
              ? i18n._(
                  t`Are you sure you want to archive this goal? It is not possible to update the progress of an archived goal.`,
                )
              : i18n._(t`Are you sure you want to save your changes?`)
          }
          onClose={() => {
            setWarningType(null);
            $showWarningModal.off();
          }}
          onSubmit={
            warningType === WARNING_MODAL_TYPES.DELETE
              ? removeGoal
              : warningType === WARNING_MODAL_TYPES.ARCHIVE
              ? onChangeArchiveStatus
              : onUpdatePublishedGoal
          }
          cancelButtonTextColor={COLORS.TEXT_MAIN}
        />
      )}
    </QuestionWrapper>
  );
};
